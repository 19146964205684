
import { defineComponent, PropType } from "vue";
import { TReportStep1 } from "@/types/report";

export default defineComponent({
	name: 'RequestStep1',
	props: {
		active: { type: Boolean },
		trls: {
			required: true,
			type: Array as PropType<Array<{ key: number, value: string}>>
		}
	},
	emits: ['next'],
	data() {
		return {
			data: {} as TReportStep1,
			errors: {
				techDescr: false,
				trlId: false,
				ip: false
			},
			custom: {
				trl: false,
				ip: {
					patent: false,
					trademark: false,
					copyright: false,
					td: false,
					ts: false,
					idr: false,
					pv: false
				}
			}
		};
	},
	mounted() {
		this.$bus.on('reports.step1', () => {
			if (this.validate()) {
				this.$emit('next', Object.assign({}, this.data));
			}
		});
	},
	methods: {
		validate() {
			this.errors.techDescr = !this.data.techDescr?.trim();
			this.errors.trlId = !this.data.trlId && !this.data.trlCustom?.trim();
			this.errors.ip = (
				!this.data.ipPatent?.trim() &&
				!this.data.ipCopyright?.trim()  &&
				!this.data.ipIndustrial?.trim() &&
				!this.data.ipPlant?.trim() &&
				!this.data.ipTrademark?.trim() &&
				!this.data.ipTradeDress?.trim() &&
				!this.data.ipTradeSecret?.trim()&&
				!this.data.ipOther?.trim());

			return Object.values(this.errors).every(o => !o );
		},
		selectHasInput(selectedIndex: number, length: number) {
			if (selectedIndex === length - 1) {
				this.custom.trl = true;
			}
		}
	}
});


import { defineComponent, PropType } from 'vue';
import { TReportStep0 } from '@/types/report';
import { TSector, TSubSector } from '@/types/sector';
import { TSourceFundingType } from '@/types/source';


export default defineComponent({
	name: 'RequestStep0',
	props: {
		active: { type: Boolean },
		sectors: {
			type: Object as PropType<TSector[]>,
			required: true
		},
		funding: {
			type: Array as PropType<TSourceFundingType[]>,
			required: true
		}
	},
	emits: ['next'],
	data() {
		return {
			data: {
				prjName: '',
				prjDescr: '',
				partnership: '',
				secId: 0,
				fndId: 0
			} as TReportStep0,
			sector: 0,
			subSectors: [] as TSubSector[],
			errors: {
				prjName: false,
				prjDescr: false,
				partnership: false,
				sector: false,
				secId: false,
				fndId: false
			},
			custom: {
				secId: false,
				subSecId: false,
				techDescr: false,
				teamDescr: false,
				exploitationPlan: false,
				trl: false,
				ip: {
					patent: false,
					trademark: false,
					copyright: false,
					td: false,
					ts: false,
					idr: false,
					pv: false
				},
				partnership: false,
				expensed: false,
				currentLp: false,
				private: {
					dept: '',
					crowd: '',
					seed: '',
					venture: '',
					ipo: ''
				},
				public: {
					regional: false,
					national: false,
					european: false,
					inter: false,
					other: false
				},
				finReport: false,
				callFunding: false,
				message: false,
				agree: false
			},

			agree: false
		};
	},
	mounted() {
		this.$bus.on('reports.step0', () => {
			if (this.validate()) {
				this.$emit('next', Object.assign({}, this.data));
			}
		});
	},
	methods: {
		validate() {
			this.errors.prjName = this.data.prjName.trim() === '';
			this.errors.prjDescr = this.data.prjDescr.trim() === '';
			this.errors.partnership = this.data.partnership.trim() === '';
			this.errors.secId = !this.data.secId;
			this.errors.fndId = !this.data.fndId;

			return Object.values(this.errors).every(o => !o );
		},
		updateSubSectors(newVal: number) {
			if (!newVal) {
				this.subSectors = [] as TSubSector[];
			} else {
				const item = this.sectors.find((item) => item.id === newVal);
				if (item) {
					this.subSectors = item.sub;
				}
			}
		},

		fundingSorted(by: string) {
			return this.funding.slice().sort((a: TSourceFundingType, b: TSourceFundingType) => {
				const textA = by === "name" ? a.name.toUpperCase() : a.identifier?.toUpperCase();
				const textB = by === "name" ? b.name.toUpperCase() : b.identifier?.toUpperCase();
				if (!textA || !textB) {
					return 0;
				}else {
					if (textA < textB) {
						return -1;
					}
					return textA > textB ? 1 : 0;
				}
			});
		}
	},
	watch: { sector: 'updateSubSectors' }
});


import { defineComponent } from 'vue';

export default defineComponent({
	name: 'RequestProgress',
	emits: ['next-step'],
	props: { active: Number },
	data() {
		return { step: this.$props.active as number };
	},
	computed: {
		step0(): boolean { return this.step === 0; },
		step1(): boolean { return this.step <= 1; },
		step2(): boolean { return this.step <= 2; },
		step3(): boolean { return this.step <= 3; },
		step4(): boolean { return this.step <= 4; }
	},
	watch: {
		active(newVal) {
			this.step = newVal;
		}
	}
});

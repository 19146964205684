
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'RequestStep4',
	props: { active: { type: Boolean } },
	data() {
		return { data: null };
	}
});


import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: 'RequestStep3',
	props: {
		active: { type: Boolean },
		investments: {
			required: true,
			type: Array as PropType<Array<string>>
		}
	},
	emits: ['next'],
	data() {
		return {
			data: {
				investment: -1,
				privateDebt: '',
				privateCrowdSourcing: '',
				privateSeed: '',
				privateVenture: '',
				privateIpo: '',
				privateOther: '',
				publicRegional: '',
				publicNational: '',
				publicEuropean: '',
				publicInter: '',
				publicOther: ''
			},
			funding: [],
			isLogged: false,
			isSent: false,
			sector: '',
			sectors: [],
			subSectors: [],
			custom: {
				secId: false,
				subSecId: false,
				techDescr: false,
				teamDescr: false,
				exploitationPlan: false,
				trl: false,
				partnership: false,
				expensed: false,
				currentLp: false,
				private: {
					dept: '',
					crowd: '',
					seed: '',
					venture: '',
					ipo: ''
				},
				public: {
					regional: false,
					national: false,
					european: false,
					inter: false,
					other: false
				},
				finReport: false,
				callFunding: false,
				message: false,
				agree: false
			},
			errors: {
				public: false,
				publicRegional: false,
				publicNational: false,
				publicEuropean: false,
				publicInter: false,
				publicOther: false,
				agree: false
			},
			agree: false
		};
	},
	mounted() {
		this.$bus.on('reports.step3', () => {
			if (this.validate()) {
				this.$emit('next', Object.assign({}, this.data));
			}
		});
	},
	methods: {
		validate() {
			this.errors.agree = !this.agree;
			this.errors.public = !this.custom.public.regional &&
				!this.custom.public.national &&
				!this.custom.public.european &&
				!this.custom.public.inter &&
				!this.custom.public.other;

			this.errors.publicRegional = this.custom.public.regional && !this.data.publicRegional?.trim();
			this.errors.publicNational = this.custom.public.national && !this.data.publicNational?.trim();
			this.errors.publicEuropean = this.custom.public.european && !this.data.publicEuropean?.trim();
			this.errors.publicInter = this.custom.public.inter && !this.data.publicInter?.trim();
			this.errors.publicOther = this.custom.public.other && !this.data.publicOther?.trim();

			return Object.values(this.errors).every(o => !o );
		}
	}
});


import { defineComponent } from "vue";

import RequestStep0 from './step0.vue';
import RequestStep1 from './step1.vue';
import RequestStep2 from './step2.vue';
import RequestStep3 from './step3.vue';
import RequestStep4 from './step4.vue';
import RequestProgress from './progress.vue';
import { TReportStep0, TReportStep1, TReportStep2, TReportStep3 } from "@/types/report";
import { TSector } from "@/types/sector";
import { TSourceFundingType } from "@/types/source";


const trls = [
	{
		key: 1,
		value: 'TRL 1 – basic principles observed'
	},
	{
		key: 2,
		value: 'TRL 2 – technology concept formulated'
	},
	{
		key: 3,
		value: 'TRL 3 – experimental proof of concept'
	},
	{
		key: 4,
		value: 'TRL 4 – technology validated in lab'
	},
	{
		key: 5,
		value: 'TRL 5 – technology validated in relevant environment (industrially relevant environment in the case of key enabling technologies)'
	},
	{
		key: 6,
		value: 'TRL 6 – technology demonstrated in relevant environment (industrially relevant ' +
			'environment in the case of key enabling technologies)'
	},
	{
		key: 7,
		value: 'TRL 7 – system prototype demonstration in operational environment'
	},
	{
		key: 8,
		value: 'TRL 8 – system complete and qualified'
	},
	{
		key: 9,
		value: 'TRL 9 – actual system proven in operational environment (competitive manufacturing ' +
			'in the case of key enabling technologies; or in space)'
	}
];

const exploitationPlan = [
	'Patents',
	'Publications',
	'Licencing',
	'Spin-off',
	'Direct commercialization',
	'Joint Venture'
];

const investments = [
	'0-10,000€',
	'10,000-50,000€',
	'50,000-100,000€',
	'100,000-500,000€',
	'500,000 – 1,000,000€',
	'1,000,000-5,000,000€',
	'5,000,000-10,000,000€',
	'> 10,000,000€'
];

export default defineComponent({
	name: 'Activate',
	components: {
		RequestStep0,
		RequestStep1,
		RequestStep2,
		RequestStep3,
		RequestStep4,
		RequestProgress
	},
	data() {
		return {
			step: 0,
			data: {
				step0: {} as TReportStep0,
				step1: {} as TReportStep1,
				step2: {} as TReportStep2,
				step3: {} as TReportStep3
			},
			form: {
				prjName: '',
				prjDescr: '',
				partnership: '',
				secId: '',
				fndId: '',
				techDescr: '',
				trlId: '',
				trlCustom: '',
				ipPatent: '',
				ipCopyright: '',
				ipIndustrial: '',
				ipPlant: '',
				ipTrademark: '',
				ipTradeDress: '',
				ipTradeSecret: '',
				ipOther: '',
				companyName: '',
				exploitationPlan: '',
				teamGeneral: '',
				teamRd: '',
				teamOperational: '',
				teamBusiness: '',
				teamDevelopment: '',
				teamSales: '',
				teamLegal: '',
				teamAffairsRegulatory: '',
				teamAffairsManager: '',
				investment: 0,
				currentLoose: 0,
				currentProfit: 0,
				privateDebt: '',
				privateCrowdSourcing: '',
				privateSeed: '',
				privateVenture: '',
				privateIpo: '',
				privateOther: '',
				publicRegional: '',
				publicNational: '',
				publicEuropean: '',
				publicInter: '',
				publicOther: '',
				file: null
			},
			loaded: {
				sectors: {} as TSector,
				funding: [] as TSourceFundingType[],
				trls,
				exploitationPlan,
				investments
			},

			isLogged: false,
			isSent: false,
			sector: '',
			subSectors: [],
			custom: {
				secId: false,
				subSecId: false,
				techDescr: false,
				teamDescr: false,
				exploitationPlan: false,
				trl: false,
				ip: {
					patent: false,
					trademark: false,
					copyright: false,
					td: false,
					ts: false,
					idr: false,
					pv: false
				},
				partnership: false,
				expensed: false,
				currentLp: false,
				private: {
					dept: '',
					crowd: '',
					seed: '',
					venture: '',
					ipo: ''
				},
				public: {
					regional: false,
					national: false,
					european: false,
					inter: false,
					other: false
				},
				finReport: false,
				callFunding: false,
				message: false,
				agree: false
			},
			errors: {},
			agree: false
		};
	},
	mounted() {
		this.$api.sector.list()
			.then((data) => {
				this.loaded.sectors = data;
			});
		this.$api.funding.list()
			.then((data) => {
				this.loaded.funding = data;
			});
	},
	methods: {
		next(data: TReportStep0 | TReportStep1 | TReportStep2 | TReportStep3) {
			switch (this.step) {

				case 0:
					Object.assign(this.data.step0, data);
					Object.assign(this.form, this.data.step0);
					break;
				case 1:
					Object.assign(this.data.step1, data);
					Object.assign(this.form, this.data.step1);
					break;
				case 2:
					Object.assign(this.data.step2, data);
					Object.assign(this.form, this.data.step2);
					break;
				case 3:
					Object.assign(this.data.step3, data);
					Object.assign(this.form, this.data.step3);
					break;

			}
			this.step++;
		},
		nextStep(n: number) {
			this.step = n;
			return;
		},
		isActive(step: number): boolean {
			return this.step === step;
		},
		stepNext() {
			this.$bus.emit('reports.step' + this.step);
		},
		stepBack() {
			this.step -= 1;
		},
		onsubmit() {
			this.step += 1;

			const fData = new FormData();
			for (const [k, v] of Object.entries(this.form)) {
				fData.append(k, v as string);
			}


			this.$api.reports.save(fData)
				.then(() => {
					this.isSent = true;
					this.$router.push('/profile/reports.html');
				})
				.catch((error) => {
					console.log(error);
				});
			return false;
		}
	}
});

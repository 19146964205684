
import { defineComponent, PropType } from "vue";
import { TReportStep2 } from "@/types/report";

export default defineComponent({
	name: 'RequestStep2',
	props: {
		active: { type: Boolean },
		exploitationPlanOptions: {
			required: true,
			type: Array as PropType<Array<string>>
		}
	},
	emits: ['next'],
	data() {
		return {
			data: {} as TReportStep2,
			ep: {
				patents: false,
				publications: false,
				licencing: false,
				spinOff: false,
				direct: false,
				joint: false,
				other: false,
				otherText: ''
			},
			funding: [],

			errors: {
				sme: false,
				outcomes: false
			},
			agree: false
		};
	},
	mounted() {
		this.$bus.on('reports.step2', () => {
			if (this.validate()) {
				this.$emit('next', Object.assign({}, this.data));
			}
		});
	},
	methods: {
		validate() {
			this.errors.sme = !this.data.sme?.trim();
			this.errors.outcomes = !this.data.outcomes?.trim();

			return Object.values(this.errors).every(o => !o );
		},

		epChange() {
			const out: string[] = [];

			if (this.ep.patents) out.push('Patents');
			if (this.ep.publications) out.push('Publications');
			if (this.ep.licencing) out.push('Licencing');
			if (this.ep.spinOff) out.push('Spin-off');
			if (this.ep.direct) out.push('Direct commercialization');
			if (this.ep.joint) out.push('Joint Venture');
			if (this.ep.otherText) out.push(this.ep.otherText);

			this.data.exploitationPlan = out.join(',');
		}
	}
});

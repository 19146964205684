
import { defineComponent } from 'vue';
import { TReportList } from "@/types/report";

export default defineComponent({
	name: 'Reports',
	data() {
		return { data: [] as TReportList[] };
	},
	methods: {
		download(id: number) {
			document.location.href = `/api/reports/download/${id}`;
		},

		async paymentPrepare(id: number) {
			const url = await this.$api.payments.prepare(id);
			if (url) {
				document.location.href = url;
			}
		}
	},
	mounted() {
		this.$api.reports.list()
			.then((data) => {
				console.log(data);
				this.data = data;
			})
			.catch(() => {
				// TODO: remove it ..
				localStorage.removeItem('user');
				//this.error = 'Backend error - unable to process request';
			});
	}
});

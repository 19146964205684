
import { defineComponent } from 'vue';
import { TUser, TUserCredentials, TUserCredentialsError, TUserErrors } from "@/types/user";

export default defineComponent({
	data() {
		return {
			profile: {
				data: {} as TUser,
				error: '',
				errors: {} as TUserErrors,
				updated: false
			},
			credentials: {
				data: {} as TUserCredentials,
				error: '',
				errors: {} as TUserCredentialsError,
				updated: false
			}
		};
	},
	name: 'ProfileSettings',
	methods: {
		validatePass() {
			const { passOld, pass, pass1 } = this.credentials.data;
			this.credentials.errors.passOld = !passOld.trim() ? 'Old password cannot be empty' : '';

			if (!pass.trim()) {
				this.credentials.errors.pass = 'Password cannot be empty';
			} else if (pass.trim().length < 5) {
				this.credentials.errors.pass = 'Your password must contain at least 5 symbols';
			} else {
				this.credentials.errors.pass = '';
			}
			if (!pass1.trim()) {
				this.credentials.errors.pass1 ='Password verification cannot be empty';
			} else if (pass !== pass1) {
				this.credentials.errors.pass1 = 'Verification and password should match';
			} else {
				this.credentials.errors.pass1 = '';
			}
		},

		validateProfile() {
			const { fName, lName, role, organization, email } = this.profile.data;
			/* validation */
			this.profile.errors.fName = !fName.trim() ? 'First name cannot be empty' : '';
			this.profile.errors.lName = !lName.trim() ? 'Last name cannot be empty' : '';
			this.profile.errors.role = !role ? 'Role cannot be empty' : '';
			this.profile.errors.organization = !organization ? 'Organization cannot be empty' : '';

			if (!email.trim()) {
				this.profile.errors.email = 'E-mail address cannot be empty';
			} else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
				this.profile.errors.email = 'Please, enter a valid e-mail address';
			} else {
				this.profile.errors.email = '';
			}
		},

		updateProfile() {
			this.profile.error = '';
			this.profile.errors = {};
			this.validateProfile();

			if (!Object.values(this.profile.errors).some(v => v)) {
				this.$api.users.update<TUser, TUserErrors>(this.profile.data)
					.catch((error) => this.profile.error = error)
					.then((res) => {
						if (res.error) {
							this.profile.error = res.error;
							this.profile.errors = res.payload;
						} else if (res.data) {
							this.profile.updated = res.data;
						}
					});
			}
			return false;
		},
		passwordChange() {
			this.credentials.errors = {};
			this.credentials.error = '';
			this.validatePass();

			if (!Object.values(this.credentials.errors).some(v => v)) {
				this.$api.users.password<boolean, TUserCredentialsError>(this.credentials.data.passOld, this.credentials.data.pass)
					.catch((error) => this.credentials.error = error)
					.then((res) => {
						if (res.error) {
							console.log(res.error);
							this.credentials.error = res.error;
							this.credentials.errors = res.payload;
						} else if (res.data) {
							this.credentials.updated = res.data;
						} else {
							this.credentials.error = 'Unable to change password';
						}
					});
			}
		}
	},
	mounted() {
		this.$api.users.get<TUser, TUserErrors>()
			.catch((error) => this.profile.error = error)
			.then((res) => {
				if (res.error) {
					this.profile.error = res.error.toString();
					if (res.payload) {
						this.profile.errors = res.payload;
					}
				} else if (res.data) {
					this.profile.data = res.data;
				} else {
					this.profile.error = 'Unable to get profile data';
				}
			});
	}
});
